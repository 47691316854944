/* eslint-disable jsx-a11y/anchor-has-content */
import { EventEmitter } from "../../events"
import InnovationsModel from "../../model/InnovationsModel"
import "./InnovationForm.css"
import React from "react"
import AWS from "aws-sdk"
import TermsAndConditions from "./termsAndConditions/termsAndConditions"
import { PulseLoader } from "react-spinners"

export default class InnovationForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activity: props.innovation,
      title: "",
      details: "",
      url: "",
      start_date: "",
      end_date: "",
      hasAccepted: true,
      bgClass: "",
      submitting: false,
      submitted: false,
      file: {},
      fileUploading: false,
      fileSelected: false,
      fileUploaded: false,
      fileUploadKey: Math.random().toString(36),
      showTC: false,
    }

    this.submitInnovation = this.submitInnovation.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
    this.toggleTCView = this.toggleTCView.bind(this)
    this.innovationModel = new InnovationsModel()
  }

  componentDidMount() {
    let bgClass = this.getInnovationClass(this.props.innovation_count)

    this.setState({ bgClass: bgClass })
  }

  getInnovationClass(count) {
    let className
    switch (count) {
      case 1:
        className = "bg-pink"
        break
      case 2:
        className = "bg-blue"
        break
      case 3:
        className = "bg-yellow"
        break
      default:
        className = ""
        break
    }

    return className
  }

  handleChange(event) {
    let { name, value } = event.target
    if (name === "hasAccepted") {
      if (event.target.checked) {
        value = true
      } else {
        value = false
      }
      this.setState({ hasAccepted: value })
    } else if (name === "title") {
      // Validate title length before updating state
      if (value.length <= 50) {
        this.setState({ [name]: value, titleError: null })
      } else {
        this.setState({
          titleError: "Title cannot exceed 50 characters",
        })
      }
    } else if (name === "details") {
      // Validate details length before updating state
      if (value.length <= 2000) {
        this.setState({ [name]: value })
      }
    } else if (name === "url") {
      // Validate details length before updating state
      if (value.length <= 255) {
        this.setState({ [name]: value, URLError: null })
      } else {
        this.setState({
          URLError: "URL cannot exceed 255 characters",
        })
      }
    } else {
      this.setState({ [name]: value })
    }
  }

  toggleTCView() {
    this.setState({ showTC: !this.state.showTC })
  }

  async submitInnovation(event) {
    event.preventDefault()
    if (this.state.fileSelected) {
      this.setState({ submitting: true }, async () => {
        await this.uploadFile()

        let submittedInnovation = await this.innovationModel.SetInnovation(
          this.state.activity.id,
          this.state.title,
          this.state.details,
          this.state.url,
          this.state.start_date,
          this.state.end_date
        )
        this.setState({
          fileUploadKey: Math.random().toString(36),
        })

        EventEmitter.dispatch("coinUpdated", {
          userPoints: this.state.activity.submission_prize,
        })

        console.log("submittedInnovation ", submittedInnovation)

        if (!submittedInnovation.error) {
          let newState = {
            activity: this.state.activity,
            title: "",
            details: "",
            url: "",
            start_date: "",
            end_date: "",
            hasAccepted: false,
            bgClass: this.state.bgClass,
            fileSelected: false,
            fileUploaded: false,
            submitted: true,
            submitting: false,
          }
          this.setState(newState, () => {
            setTimeout(() => {
              this.setState({ submitted: false }, () => {
                this.props.onSubmit()
              })
            }, 5000)
          })
        }
      })
    }
  }

  async uploadFile() {
    return new Promise((resolve, reject) => {
      this.setState({ fileUploading: true }, async () => {
        try {
          // S3 Bucket Name
          const S3_BUCKET = "images.stogoworld.com"

          // S3 Region
          const REGION = "ap-south-1"

          // S3 Credentials
          AWS.config.update({
            accessKeyId: "AKIA2IIRR47UKAQF6UUK",
            secretAccessKey: "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",
          })
          const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
          })

          // Files Parameters
          let time = new Date().getTime()
          let filename = time + this.state.file.name.replace(/\s/g, "")
          const params = {
            Bucket: S3_BUCKET,
            Key: `tempFeeds/${filename}`,
            Body: this.state.file,
          }

          // Uploading file to s3
          var upload = s3
            .putObject(params)
            .on("httpUploadProgress", (evt) => {
              // File uploading progress
              console.log(
                "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
              )
            })
            .promise()

          await upload
            .then((data) => {
              // File successfully uploaded
              let link = `https://s3.ap-south-1.amazonaws.com/images.stogoworld.com/tempFeeds/${filename}`
              this.setState(
                {
                  url: link,
                  fileUploaded: true,
                  fileUploading: false,
                },
                () => {
                  console.log("File uploaded successfully.")
                  resolve(link)
                }
              )
            })
            .catch((err) => {
              this.setState({ fileUploading: false })
              reject(err)
            })
        } catch (err) {
          this.setState({ fileUploading: false })
          reject(err)
        }
      })
    })
  }

  handleFileChange(e) {
    // Uploaded file
    const file = e.target.files[0]
    // Changing file state
    this.setState({ file: file, fileSelected: true })
  }

  render() {
    let startMinDate = new Date(this.state.activity.start_date)
      .toISOString()
      .split("T")[0]
    let endMinDate =
      this.state.start_date !== "" ? this.state.start_date : startMinDate
    let endMaxDate =
      new Date().getTime() < new Date(this.state.activity.end_date).getTime()
        ? new Date().toISOString().split("T")[0]
        : new Date(this.state.activity.end_date).toISOString().split("T")[0]
    let startMaxDate =
      this.state.end_date !== "" ? this.state.end_date : endMaxDate
    let currDate = new Date().toISOString().split("T")[0]

    let submitting = "SUBMIT"
    // let uploading = "Upload"
    if (this.state.submitting) {
      submitting = (
        <PulseLoader
          loading="true"
          color="white"
          aria-label="Loading Spinner"
          data-testid="loader"></PulseLoader>
      )
    }
    // if (this.state.fileUploading) {
    //   uploading = (
    //     <PulseLoader
    //       loading="true"
    //       color="grey"
    //       aria-label="Loading Spinner"
    //       data-testid="loader"></PulseLoader>
    //   )
    // }

    return (
      <>
        <form
          className={this.state.bgClass}
          onSubmit={this.submitInnovation}
          style={{ display: this.state.showTC ? "none" : "block" }}>
          <img
            src={this.state.activity.image}
            alt="Innovation"
            className="img-thumbnail"
          />{" "}
          <br />
          <br />
          {(() => {
            if (this.state.submitted) {
              return (
                <div className="alert alert-success" role="alert">
                  Submitted
                </div>
              )
            }
          })()}
          <div className="mb-3">
            {/* <label htmlFor="eventTitle" className="form-label">
              Event Title{" "}
              <span className="text-danger">{this.state.titleError}</span>
            </label> */}
            <input
              type="text"
              className="form-control"
              id="eventTitle"
              name="title"
              placeholder="Enter Event Title"
              value={this.state.title}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="mb-3">
            {/* <label htmlFor="eventDetails" className="form-label">
              Event Details{" "}
              <span className="character-count">
                ({this.state.details.length}/2000)
              </span>
            </label> */}
            <textarea
              className="form-control"
              id="eventDetails"
              rows="3"
              placeholder="Enter Event Details (0/2000)"
              name="details"
              value={this.state.details}
              onChange={this.handleChange}
              required></textarea>
          </div>
          <div className="mb-3">
            <label htmlFor="eventURL" className="form-label">
              Please upload the video
              {/* <a href="!#" data-bs-toggle="tooltip" data-bs-title="Drive URL Only"><i className="fa fa-info-circle" aria-hidden="true"></i></a> */}
              <span className="text-danger">{this.state.URLError}</span>
            </label>
            <input
              type="hidden"
              className="form-control"
              id="eventURL"
              placeholder="Enter Drive URL For Video"
              name="url"
              value={this.state.url}
              onChange={this.handleChange}
              required
            />
            <div className="input-group mb-3">
              <input
                type="file"
                // accept=".avi, .wmv, .mov, .flv, .3gpp, .webm, .DNxHR, .ProRes, .CineForm, .HEVC"
                accept="video/*"  // This allows any video format 
                className="form-control"
                key={this.state.fileUploadKey}
                onChange={this.handleFileChange}
              />
              {/* <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  disabled={!this.state.fileSelected}
                  hidden={this.state.fileUploaded}
                  onClick={this.uploadFile}>
                  {uploading}
                </button>
              </div> */}
            </div>
          </div>
          <div className="row mb-3" style={{ display: "none" }}>
            <label htmlFor="Period" className="form-label">
              Period of Occurrence
            </label>
            <div className="fourty">
              <div className="input-group">
                <input
                  type="date"
                  className="form-control"
                  id="start_date"
                  name="start_date"
                  value={currDate}
                  onChange={this.handleChange}
                  min={startMinDate}
                  max={startMaxDate}
                  required
                />
              </div>
            </div>
            <div className="twenty text-center">
              <p className="my-2">to</p>
            </div>
            <div className="fourty">
              <div className="input-group">
                <input
                  type="date"
                  className="form-control"
                  id="end_date"
                  name="end_date"
                  value={currDate}
                  onChange={this.handleChange}
                  min={endMinDate}
                  max={endMaxDate}
                  required
                />
              </div>
            </div>
          </div>
          <div className="form-group tc">
            <input
              type="checkbox"
              className="form-check-input"
              id="hasAccepted"
              name="hasAccepted"
              checked={this.state.hasAccepted}
              value={this.state.hasAccepted}
              onChange={this.handleChange}
            />
            <label className="form-check-label" htmlFor="hasAccepted">
              &nbsp;
            </label>
            <span className="link" onClick={this.toggleTCView}>
              Terms & Conditions
            </span>
          </div>
          <button
            type="submit"
            className="button"
            disabled={!this.state.hasAccepted}>
            {submitting}
          </button>
        </form>

        <div
          className={this.state.bgClass}
          style={{ display: this.state.showTC ? "block" : "none" }}>
          <TermsAndConditions
            termsAndConditions={this.state.activity.terms_conditions}
            onHide={this.toggleTCView}></TermsAndConditions>
        </div>
      </>
    )
  }
}
