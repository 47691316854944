import "./Quiz.css"
import React from "react"
import Question from "./question/Question"
import QuizModel from "../../../model/QuizModel"
import UserModel from "../../../model/UserModel"
import ContestModel from "../../../model/ContestModel"
import { EventEmitter } from "../../../events"

export default class Quiz extends React.Component {
  constructor(params) {
    super(params)
    this.state = {
      contest: params.contest,
      questions: [],
      currentIndex: 0,
      score: 0,
      timeTaken: 0,
    }
    this.quizModel = new QuizModel()
    this.userModel = new UserModel()
    this.contestModel = new ContestModel()
    this.changeCurrentIndex = this.changeCurrentIndex.bind(this)
  }

  componentDidMount() {
    this.fetchQuestionsByContest()
  }

  componentWillUnmount() {
    this.setState({})
  }

  async fetchQuestionsByContest() {
    const response = await this.quizModel.GetContestQuestions(
      this.state.contest.id
    )
    this.setState({
      contest: this.state.contest,
      questions: response.data,
      currentIndex: this.state.currentIndex,
      score: 0,
      timeTaken: 0,
    })
  }

  async changeCurrentIndex(questionAttributes) {
    const idx = this.state.currentIndex + 1
    let scoreUpdate = this.state.score
    if (questionAttributes.isRightAnswer) {
      scoreUpdate++
      // timeTaken += questionAttributes.timeTaken
    }
    let newQuestions = this.state.questions
    newQuestions[this.state.currentIndex].isRightAnswer =
      questionAttributes.isRightAnswer
    newQuestions[this.state.currentIndex].selectedAnswer =
      questionAttributes.selectedAnswer
    newQuestions[this.state.currentIndex].usedTime =
      questionAttributes.timeTaken

    // Log the current question details
    console.log("Current Question:", this.state.questions[this.state.currentIndex]);
    console.log("Selected Answer:", questionAttributes.selectedAnswer);
    console.log("Is Right Answer:", questionAttributes.isRightAnswer);
    console.log("Time Taken:", questionAttributes.timeTaken);


    if (this.state.questions.length > idx) {
      this.setState((prevState) => ({
        questions: newQuestions,
        contest: this.state.contest,
        currentIndex: idx,
        score: scoreUpdate,
        timeTaken: prevState.timeTaken + questionAttributes.timeTaken,
      }))
    } else {
      this.setState((prevState) => ({
        questions: this.state.questions,
        contest: this.state.contest,
        currentIndex: this.state.currentIndex,
        score: scoreUpdate,
        timeTaken: prevState.timeTaken + questionAttributes.timeTaken,
      }))

      let answers = this.state.questions.map((question) => {
        return {
          question_id: question.id,
          answer: question.selectedAnswer,
        }
      })

       // Log the final answers before submitting
       console.log("Final Answers:", answers);

      await this.contestModel.SubmitContestAnswer(
        this.state.contest.id,
        answers,
        this.state.timeTaken
      )

      if (this.state.contest.is_default_contest === "0") {
        let coins = parseInt(this.state.contest.entry_prize, 10)
        if (this.state.score === this.state.questions.length) {
          coins += parseInt(this.state.contest.full_mark_prize, 10)
        }

        EventEmitter.dispatch("quizAttended")
        EventEmitter.dispatch("coinUpdated", {
          userPoints: coins,
        })
      }
      let statistics = {
        totalQuestions: this.state.questions.length,
        correctAnswers: this.state.score,
        timeTaken: this.state.timeTaken,
        questions: this.state.questions,
      }
      this.props.onClick(statistics)
    }
  }

  render() {

    console.log("Current State:", this.state); // Logs the full state

    if (this.state.questions.length > 0) {
      let totalQuestions = this.state.questions.length
      let pos = this.state.currentIndex + 1
      let remaining = totalQuestions - pos
      let question = this.state.questions[this.state.currentIndex]
      return (
        <Question
          key={question.id}
          onClick={this.changeCurrentIndex}
          question={question}
          contest={this.state.contest}
          position={pos}
          remaining={remaining}></Question>
      )
    } else {
      return
    }
  }
}
