import QuizModel from "../../../../model/QuizModel"
import "./ReviewAnswer.css"
import React from "react"

export default class ReviewAnswer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            contest: props.contest,
            question: props.question,
            questionPosition: props.questionPosition,
        }

        this.quizModel = new QuizModel()
    }

    componentWillUnmount() {
        this.setState({})
    }

    static getDerivedStateFromProps(props, state) {
        if (props !== state) {
            //Change in props 
            return {
                contest: props.contest,
                question: props.question,
                questionPosition: props.questionPosition,
            }
        }
        return null // No change to state 
    }

    render() {
        let decryptedAnswer = this.quizModel.decryptAnswer(this.state.question.answer)
        switch (decryptedAnswer) {
            case "a":
                decryptedAnswer = this.state.question.optiona
                break;
            case "b":
                decryptedAnswer = this.state.question.optionb
                break;
            case "c":
                decryptedAnswer = this.state.question.optionc
                break;
            case "d":
                decryptedAnswer = this.state.question.optiond
                break;
            default:
                decryptedAnswer = this.state.question.optione
                break;
        }
        let optionClass
        if (this.state.question.selectedAnswer === decryptedAnswer) {
            optionClass = "correct"
        } else {
            optionClass = "wrong"
        }

        return (
            <div key={this.state.question.id} className="quiz-play">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dgreen-txt txt-20">Review Answers</div>
                            <div className="name">{this.state.contest.name}</div>
                            <h2>Question {this.state.questionPosition + 1}</h2>
                            <h3>{this.state.question.question}</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={`form-check answer ${this.state.question.selectedAnswer === this.state.question.optiona ? optionClass : ''} ${decryptedAnswer === this.state.question.optiona ? 'correct' : ''}`}>
                                <input className="form-check-input" type="radio" name="quizone" id={`quizone1-${this.state.question.id}`}
                                    value="option1" defaultChecked={this.state.question.selectedAnswer === this.state.question.optiona} disabled />
                                <label className="form-check-label" htmlFor={`quizone1-${this.state.question.id}`}>
                                    {this.state.question.optiona}
                                </label>
                            </div>
                            <div className={`form-check answer ${this.state.question.selectedAnswer === this.state.question.optionb ? optionClass : ''} ${decryptedAnswer === this.state.question.optionb ? 'correct' : ''}`}>
                                <input className="form-check-input" type="radio" name="quizone" id={`quizone2-${this.state.question.id}`}
                                    value="option2" defaultChecked={this.state.question.selectedAnswer === this.state.question.optionb} disabled />
                                <label className="form-check-label" htmlFor={`quizone2-${this.state.question.id}`}>
                                    {this.state.question.optionb}
                                </label>
                            </div>
                            <div className={`form-check answer ${this.state.question.selectedAnswer === this.state.question.optionc ? optionClass : ''} ${decryptedAnswer === this.state.question.optionc ? 'correct' : ''}`}>
                                <input className="form-check-input" type="radio" name="quizone" id={`quizone3-${this.state.question.id}`}
                                    value="option3" defaultChecked={this.state.question.selectedAnswer === this.state.question.optionc} disabled />
                                <label className="form-check-label" htmlFor={`quizone3-${this.state.question.id}`}>
                                    {this.state.question.optionc}
                                </label>
                            </div>
                            <div className={`form-check answer ${this.state.question.selectedAnswer === this.state.question.optiond ? optionClass : ''} ${decryptedAnswer === this.state.question.optiond ? 'correct' : ''}`}>
                                <input className="form-check-input" type="radio" name="quizone" id={`quizone4-${this.state.question.optiond}`}
                                    value="option4" defaultChecked={this.state.question.selectedAnswer === this.state.question.optiond} disabled />
                                <label className="form-check-label" htmlFor={`quizone4-${this.state.question.optiond}`}>
                                    {this.state.question.optiond}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6"> <img alt="" className="quiz-img" src={this.state.question.image} /></div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        {this.state.questionPosition !== 0 && (
                            <button className="nxt-btn" onClick={this.props.onPrevious}>PREVIOUS</button>
                        )}
                            <button className="nxt-btn" onClick={this.props.onNext}>NEXT</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}